$mainColor: darkcyan;



$width: 768px;


@mixin mobile {
    @media (max-width: #{$width}){
        @content
}
}
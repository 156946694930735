@import "../../global.scss";


.works{
    background-color: $mainColor;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;


    .arrow{
        height: 50px;
        position: absolute;
        


        &.left{
            left: 100px;
            transform: rotateY(180deg);
            cursor: pointer;
        }


        &.right{
            right: 100px;
            cursor: pointer;
        }
    }

    .slider{
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;

        @include mobile{
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }


        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;


            .item{                  /*styling for box*/
                width: 700px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile{
                    height: 200px;
                    width: 90%;
                    margin: 20px 0;
                }
        

                .left{          
                    flex: 4;            /*going to split container into 12 pieces, 4 of them will be left*/
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer{
                        width: 90%;
                        height: 80%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @include mobile{
                            width: 100%;
                            height: 100%;
                        }


                        .imgContainer{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: coral;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            @include mobile{
                                width: 20px;
                                height: 20px;
                            }

                        }
                            img{
                                width: 25px;
                            }
                            
                            h2{
                                font-size: 20px;
                            }
                            p{
                                font-size: 13px;
                                
                            }

                            span{
                                font-size: 12px;
                                font-weight: 600;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        
                    }
                    
                    
                }
                .right{          
                    flex: 8;       
                    height: 100%;     
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    overflow: hidden;


                    img{
                        width: 400px;
                        transform: rotate(-10deg);
                    }
                    
                    
                }
            }
        }
    }

    
}
@import "../../global.scss";   /*color object we made imported*/


.menu{
    width: 300px;
    height: 100vh;
    background-color: $mainColor;
    position: fixed;
    top: 0;
    right: -300px;  /*to hide the column*/
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    


    &.active{
        right: 0;  /*to unhide column*/
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;
        color: white;
        width: 60%;

        li{ 
            margin-bottom: 25px;  /*creates space between the list vertically*/
            a{
                font-size: inherit;             /*makes the links white instead of purple when clicked*/
                color: inherit;
                text-decoration: none;
                scroll-behavior: smooth;
            }

            &:hover{
                font-weight: 500;   /*gives highlight animation when hovering over links*/
            }
        }
    }
    
}
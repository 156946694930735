@import "../../global.scss";



.topbar{
    width: 100%;
    height: 70px;
    background-color: white; /*main color was set to dark blue in global.scss, made the color into an object*/
    position: fixed;
    top: 0; /*Keeps top bar at top*/
    z-index: 3;  /*allows topbar to stay there when you scroll down, works as a layer*/
    color: $mainColor;
    transition: all 1s ease;


    .wrapper{
        padding: 10px 30px;  /*10px from top and bottom 30 px from left and right*/
        display: flex; /*whatever we right here will now be horizontal*/
        align-items: center;
        justify-content: space-between; /*spaces out the elements at the top*/

        .left{

            display: flex;
            align-items: center;

            .logo{
                font-size: 40px;
                font-weight: 700; /*font width*/
                text-decoration: none; /*removed the underline of the text*/
                color: inherit;
                margin-right: 40px;
            }

            .itemContainer{
                display: flex;
                align-items: center; /*aligns items in a straight line*/
                margin-left: 30px; /*Creates space between objects*/

                @include mobile{
                    display: none;
                }

                .icon{
                    font-size: 18px;
                    margin-right: 5px;
                }

                span{
                    font-size: 25px;
                    font-weight: 400;
                }
            }
        }

        .right{
            .hamburger{    /*columns to the right*/
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;

                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 2s ease;
                }
            }
        }
    }

    &.active{
        background-color: $mainColor;
        color: white;

        .hamburger{
            span{
                &:first-child{
                    background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}


@import "../../global.scss";

.contact{
    background-color: white;
    display: flex;

    @include mobile {
        flex-direction: column;
    }

    .left{
        flex: 2;
        overflow: hidden;
    }

    img{
        height: 100%;

        @include mobile{
            height: 100%;
            width: 100%;
        }
    }

    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        h2{
            font-size: 30px;
        }

        form{
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;


            input{
                width: 300px;
                height: 30px;
                font-size: 14px;

                @include mobile{
                    width: 200px;
                    height: 20px;
                }

            }

            textarea{
                width: 300px;
                height: 200px;
                font-size: 14px;

                @include mobile{
                    width: 200px;
                    height: 100px;
                }
            }

            button{
                width: 150px;
                height: 30px;
                color: white;
                background-color: green;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;


                &:focus{
                    outline: none;
                }
            }

            span{
                color: green;
            }
        }
    }
}